import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import App from './layout/App';
import reducer from './store/reducer';
import config from './config';
import './assets/scss/style.scss';
import i18next from 'i18next';
import cs from './language/cs.json';
import en from './language/en.json';
import el from './language/el.json';
import de from './language/de.json';
import hu from './language/hu.json';
import ru from './language/ru.json';
import sk from './language/sk.json';
import es from './language/es.json';

const store = createStore(reducer);

i18next.init({
    lng: 'en', 
    debug: false,
    resources: {
      en: {
        translation: en["en"]
      },
      sk: {
        translation: sk["sk"]
      },
      cs: {
        translation: cs["cs"]
      },
      ru: {
        translation: ru["ru"]
      },
      de: {
        translation: de["de"]
      },
      es: {
        translation: es["es"]
      },
      hu: {
        translation: hu["hu"]
      },
      el: {
        translation: el["el"]
      }
    }
});

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter basename={config.basename}>
            <App />
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);