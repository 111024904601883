import React, { useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from './../themes';
import Routes from '../Routes';
import NavigationScroll from './NavigationScroll';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const App = () => {
    const customization = useSelector((state) => state.customization);
    
    return (
        <React.Fragment>
                <IntlProvider locale={customization.locale} defaultLocale="en">
                    <CssBaseline />
                    <NavigationScroll>
                        <StylesProvider jss={jss}>
                            <ThemeProvider theme={theme(customization)}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Routes />
                                </MuiPickersUtilsProvider>
                            </ThemeProvider>
                        </StylesProvider>
                    </NavigationScroll>
                </IntlProvider>
        </React.Fragment>
    );
};

export default App;