import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import Loader from './component/Loader/Loader';
const Home = lazy(() => import('./views/Frontend/Home'));
const GiftCard = lazy(() => import('./views/Frontend/GiftCard'));
const GiftCardVoucher = lazy(() => import('./views/Frontend/GiftCard/GiftCardVoucher'));

const TermsConditions = lazy(() => import('./views/Frontend/Pages/TermsConditions'));
const Affiliate = lazy(() => import('./views/Frontend/Pages/Affiliate'));
const Contact = lazy(() => import('./views/Frontend/Pages/Contact'));


const Routes = () => {
    return (
        <AnimatePresence>
            <Suspense fallback={<Loader />}>
                <Switch>
                    <Route exact path="/" component={GiftCard} />
                    <Route exact path="/Home" component={GiftCard} />
                    <Route exact path="/GiftCard" component={Home} />
                    <Route exact path="/GiftCardVoucher" component={GiftCardVoucher} />
                    <Route exact path="/termsconditions" component={TermsConditions} />
                    <Route exact path="/bookonline" component={Affiliate} />
                    <Route exact path="/aboutus" component={Contact} />
                </Switch>
            </Suspense>
        </AnimatePresence>
    );
};

export default Routes;